import '@less/media.less';

const screenWidth = window.innerWidth;
const stickyHeader = $('.elementor-element-712ccd7');
const heightNav = stickyHeader[0].offsetHeight;

if (screenWidth < 991) {
   $('.jet-slider').remove();
   $('body').css({ paddingTop: `${heightNav}px` });

   $(window).on('resize', () => {
      if (screenWidth < 991) {
         $('.jet-slider').remove();
         $('body').css({ paddingTop: `${heightNav}px` });
      } else {
         $('body').css({ paddingTop: 0 });
      }
   });

   setInterval(() => {
      if ($('.jet-mobile-menu').hasClass('jet-mobile-menu-active')) {
         $('body').css({ overflow: 'hidden' });
      } else {
         $('body').css({ overflow: '' });
      }
   }, 300);
}
